import axios from "axios";
import { useEffect, useState } from "react";
import TournamentPreview from "./Tournament_preview";

import Loading from "../../subComp/loading"
const AvailableTournaments =() =>{
    const [tournament_list,SetTournament_list]=useState([]);
    const [loading,SetLoading]=useState(false);
    const get_tournament_list = async () => {
      try {
        const tournament_api = "https://aqueous-falls-70675.herokuapp.com/api/v1/collect/tournaments/TournamentsAvailable";
      axios.get(tournament_api).then((response) => {
        if ((response.data.status === 201) || (response.data.status === 201)) {
          SetTournament_list(response.data.results);
         var tournament = {
          tournament:response.data.results
         };
          localStorage.setItem("tournament", JSON.stringify(tournament));
         
          SetLoading(true);
        }else{
          console.log(response.data.errors);
        }
      })
      } catch (error) {
        console.log(error);
      }
      
    };
  useEffect (()=>{
    get_tournament_list()
    
  }, []);

    return(
            <div>
   
    <section id="tournaments-section">
        <div className="overlay pt-120 pb-120">
            <div className="container wow fadeInUp">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-8 text-center">
                        <div className="section-header">
                            <h2 className="title">Browse Tournaments</h2>
                            <p>Find the perfect tournaments for you. Head to head matches
                                where you pick the game, rules and prize.</p>
                        </div>
                    </div>
                </div>
               {loading ? (  tournament_list.map((tournament) => (
          <TournamentPreview key={tournament.id} tournament={tournament} />
        ))):(<Loading />)}
          
             
                
            </div>
        </div>
    </section>
   
    </div>
    );
};

export default AvailableTournaments;