
const TournamentMatches = (props) => {
   


    return (
        <div className="tab-pane fade" id="matches" role="tabpanel" aria-labelledby="matches-tab">
        <div className="matches pb-120">
            <div className="container overlay-cus">
                <div className="row w-cus">
                    <div className="col-lg-12">
                        <div className="unscheduled">
                            <h4>Unscheduled Matches</h4>
                            <div className="single-matches d-flex align-items-center justify-content-between">
                                <div className="left">
                                    <p>14:23</p>
                                </div>
                                <div className="mid d-flex align-items-center justify-content-between">
                                    <div className="player left-player d-flex align-items-center">
                                        <p>Angila Hazel</p>
                                        <span className="tier victory">Tier VII</span> 
                                        <img src="/assets/images/participant-1.png" alt="image"/>
                                    </div>
                                    <h5 className="vs">
                                        <span>1</span>
                                        <span>:</span>
                                        <span>0</span>
                                    </h5>
                                    <div className="player right-player d-flex align-items-center">
                                        <img src="/assets/images/participant-2.png" alt="image"/>
                                        <p>Anisa Greiner</p>
                                        <span className="tier second">Tier VII</span> 
                                    </div>
                                </div>
                                <div className="right">
                                    <img src="/assets/images/right-arrows.png" alt="icon"/>
                                </div>
                            </div>
                        </div>
                        <div className="yesterday">
                            <h4>Yesterday</h4>
                            <div className="single-matches d-flex align-items-center justify-content-between">
                                <div className="left">
                                    <p>14:23</p>
                                </div>
                                <div className="mid d-flex align-items-center justify-content-between">
                                    <div className="player left-player d-flex align-items-center">
                                        <p>Angila Hazel</p>
                                        <span className="tier victory">Tier VII</span> 
                                        <img src="/assets/images/participant-1.png" alt="image"/>
                                    </div>
                                    <h5 className="vs">
                                        <span>1</span>
                                        <span>:</span>
                                        <span>0</span>
                                    </h5>
                                    <div className="player right-player d-flex align-items-center">
                                        <img src="/assets/images/participant-2.png" alt="image"/>
                                        <p>Anisa Greiner</p>
                                        <span className="tier second">Tier VII</span> 
                                    </div>
                                </div>
                                <div className="right">
                                    <img src="/assets/images/right-arrows.png" alt="icon"/>
                                </div>
                            </div>
                            <div className="single-matches d-flex align-items-center justify-content-between">
                                <div className="left">
                                    <p>14:23</p>
                                </div>
                                <div className="mid d-flex align-items-center justify-content-between">
                                    <div className="player left-player d-flex align-items-center">
                                        <p>Angila Hazel</p>
                                        <span className="tier victory">Tier VII</span> 
                                        <img src="/assets/images/participant-3.png" alt="image"/>
                                    </div>
                                    <h5 className="vs">
                                        <span>1</span>
                                        <span>:</span>
                                        <span>0</span>
                                    </h5>
                                    <div className="player right-player d-flex align-items-center">
                                        <img src="/assets/images/participant-4.png" alt="image"/>
                                        <p className="eliminated">Anisa Greiner</p>
                                        <span className="tier second">Tier VII</span> 
                                    </div>
                                </div>
                                <div className="right">
                                    <img src="/assets/images/right-arrows.png" alt="icon"/>
                                </div>
                            </div>
                            <div className="single-matches d-flex align-items-center justify-content-between">
                                <div className="left">
                                    <p>14:23</p>
                                </div>
                                <div className="mid d-flex align-items-center justify-content-between">
                                    <div className="player left-player d-flex align-items-center">
                                        <p>Angila Hazel</p>
                                        <span className="tier victory">Tier VII</span> 
                                        <img src="/assets/images/participant-5.png" alt="image"/>
                                    </div>
                                    <h5 className="vs">
                                        <span>1</span>
                                        <span>:</span>
                                        <span>0</span>
                                    </h5>
                                    <div className="player right-player d-flex align-items-center">
                                        <img src="/assets/images/participant-6.png" alt="image"/>
                                        <p>Anisa Greiner</p>
                                        <span className="tier second">Tier VII</span> 
                                    </div>
                                </div>
                                <div className="right">
                                    <img src="/assets/images/right-arrows.png" alt="icon"/>
                                </div>
                            </div>
                            <div className="single-matches d-flex align-items-center justify-content-between">
                                <div className="left">
                                    <p>14:23</p>
                                </div>
                                <div className="mid d-flex align-items-center justify-content-between">
                                    <div className="player left-player d-flex align-items-center">
                                        <p>Angila Hazel</p>
                                        <span className="tier victory">Tier VII</span> 
                                        <img src="/assets/images/participant-7.png" alt="image"/>
                                    </div>
                                    <h5 className="vs">
                                        <span>1</span>
                                        <span>:</span>
                                        <span>0</span>
                                    </h5>
                                    <div className="player right-player d-flex align-items-center">
                                        <img src="/assets/images/participant-2.png" alt="image"/>
                                        <p className="eliminated">Anisa Greiner</p>
                                        <span className="tier second">Tier VII</span> 
                                    </div>
                                </div>
                                <div className="right">
                                    <img src="/assets/images/right-arrows.png" alt="icon"/>
                                </div>
                            </div>
                            <div className="single-matches d-flex align-items-center justify-content-between">
                                <div className="left">
                                    <p>14:23</p>
                                </div>
                                <div className="mid d-flex align-items-center justify-content-between">
                                    <div className="player left-player d-flex align-items-center">
                                        <p>Angila Hazel</p>
                                        <span className="tier victory">Tier VII</span> 
                                        <img src="/assets/images/participant-3.png" alt="image"/>
                                    </div>
                                    <h5 className="vs">
                                        <span>1</span>
                                        <span>:</span>
                                        <span>0</span>
                                    </h5>
                                    <div className="player right-player d-flex align-items-center">
                                        <img src="/assets/images/participant-5.png" alt="image"/>
                                        <p>Anisa Greiner</p>
                                        <span className="tier second">Tier VII</span> 
                                    </div>
                                </div>
                                <div className="right">
                                    <img src="/assets/images/right-arrows.png" alt="icon"/>
                                </div>
                            </div>
                            <div className="single-matches d-flex align-items-center justify-content-between">
                                <div className="left">
                                    <p>14:23</p>
                                </div>
                                <div className="mid d-flex align-items-center justify-content-between">
                                    <div className="player left-player d-flex align-items-center">
                                        <p>Angila Hazel</p>
                                        <span className="tier victory">Tier VII</span> 
                                        <img src="/assets/images/participant-2.png" alt="image"/>
                                    </div>
                                    <h5 className="vs">
                                        <span>1</span>
                                        <span>:</span>
                                        <span>0</span>
                                    </h5>
                                    <div className="player right-player d-flex align-items-center">
                                        <img src="/assets/images/participant-8.png" alt="image"/>
                                        <p className="eliminated">Anisa Greiner</p>
                                        <span className="tier second">Tier VII</span> 
                                    </div>
                                </div>
                                <div className="right">
                                    <img src="/assets/images/right-arrows.png" alt="icon"/>
                                </div>
                            </div>
                            <div className="single-matches d-flex align-items-center justify-content-between">
                                <div className="left">
                                    <p>14:23</p>
                                </div>
                                <div className="mid d-flex align-items-center justify-content-between">
                                    <div className="player left-player d-flex align-items-center">
                                        <p>Angila Hazel</p>
                                        <span className="tier victory">Tier VII</span> 
                                        <img src="/assets/images/participant-6.png" alt="image"/>
                                    </div>
                                    <h5 className="vs">
                                        <span>1</span>
                                        <span>:</span>
                                        <span>0</span>
                                    </h5>
                                    <div className="player right-player d-flex align-items-center">
                                        <img src="/assets/images/participant-1.png" alt="image"/>
                                        <p className="eliminated">Anisa Greiner</p>
                                        <span className="tier second">Tier VII</span> 
                                    </div>
                                </div>
                                <div className="right">
                                    <img src="/assets/images/right-arrows.png" alt="icon"/>
                                </div>
                            </div>
                            <div className="single-matches d-flex align-items-center justify-content-between">
                                <div className="left">
                                    <p>14:23</p>
                                </div>
                                <div className="mid d-flex align-items-center justify-content-between">
                                    <div className="player left-player d-flex align-items-center">
                                        <p>Angila Hazel</p>
                                        <span className="tier victory">Tier VII</span> 
                                        <img src="/assets/images/participant-4.png" alt="image"/>
                                    </div>
                                    <h5 className="vs">
                                        <span>1</span>
                                        <span>:</span>
                                        <span>0</span>
                                    </h5>
                                    <div className="player right-player d-flex align-items-center">
                                        <img src="/assets/images/participant-3.png" alt="image"/>
                                        <p>Anisa Greiner</p>
                                        <span className="tier second">Tier VII</span> 
                                    </div>
                                </div>
                                <div className="right">
                                    <img src="/assets/images/right-arrows.png" alt="icon"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  };
  
  export default TournamentMatches;
  