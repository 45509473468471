
import axios from 'axios';
import { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { useParams } from 'react-router-dom';
import Header from "../subComp/Header";
import FullLoad from '../subComp/FullLoad';
import TournamentBracket from './subComp/TournamentBracket';
import TournamentMatches from './subComp/TournamentMatches';
import TournamentOverview from './subComp/TournamentOverview';
import TournamentParticipants from './subComp/TournamentParticipants';
import TournamentPrizes from './subComp/TournamentPrizes';
import JoinTournamentButton from './subComp/JoinTournamentButton';
const TournamentPage = (props) => {
  const user = props.user;
const [loading,SetLoading]=useState(true);

const [tournament,Settournament]=useState(null);
const params =  useParams();

useEffect (()=>{
    const tour_id = params.id;
    const get_tournament_info = async () => {

        const tournament_api = "https://aqueous-falls-70675.herokuapp.com/api/v1/collect/tournaments/Tournament";
      axios.post(tournament_api,{tour_id}).then((response) => {
        if ((response.data.status === 201)) {
          Settournament(response.data.results);  
           SetLoading(false);
        }else{
            window.location.href = "/";
        }
      }).catch (error => {
        // window.location.href = "/";
        
      }) 
    }
get_tournament_info()

}, []);

  const [page, setPage] = useState('start');



  return (
   
    <div> <Header />
      { loading ? (<FullLoad/>) : (<div>
   
    
    <section id="banner-section" className="inner-banner tournaments" style={{backgroundSize:"cover",backgroundImage:"linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(https://data3.origin.com/asset/content/dam/originx/web/app/games/apex/apex/F2P/season-10/pdp-module/Apex_Legends_Emergence_squadShot_en_ww_v1.jpg/7aef4360-002e-40df-ac7d-ba048eb2464e/original.jpg)"}}>
     
       
        <div className="container">
            <div className="headign-info" >
                <div className="top-area" >
                    <div className="row" >
                        
                        <div className="col-lg-4 col-md-4 col-sm-4 d-flex justify-content-center">
                            <img src="/assets/images/character_01.png" alt="imagech" />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 d-flex align-items-center justify-content-sm justify-content-center">
                            <div className="mid-area text-center">
                                <img src={"/assets/images/text-img.png"} alt="imagetxt" />
                                <h5>{tournament.title}</h5>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 zindex">
                            <img src="/assets/images/character_02.png" alt="imagech2" />
                        </div>
                    </div>
                </div>
                <div className="bottom-area">
                    <div className="bottom">
                        <div className="row d-flex justify-content-between">
                            <div className="col-lg-8 col-md-8 justify-content-sm-center d-grid">
                                <h3>{tournament.title}</h3>
                                <div className="title-bottom d-flex">
                                    <div className="time-area bg">
                                        <img src="/assets/images/waitng-icon.png" alt="imageWaiting " />
                                        <span>Starts in <span></span></span>
                                        <span className="time"> {" "}
                    <Countdown date={new Date(tournament.date)} /> {" "} </span>
                                    </div>
                                    <div className="date-area bg">
                                        <span className="date">{new Date(tournament.date).toDateString()}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 text-center">
                                <h2 className="tour_prizes">{tournament.prize}</h2>

                         {user ? (<JoinTournamentButton tour={tournament} user={user} />):( <a href="registration.html" class="cmn-btn" data-toggle="modal" data-target=".bd-example-modal-lg">login and join</a>)}       
                              
                            </div>
                        </div>
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a  onClick={() => {
                    
                    setPage('overview');
                  }} className="nav-link active" id="overview-tab" data-toggle="tab" href="#overview" role="tab" aria-controls="overview" aria-selected="true">overview</a>
                            </li>
                            <li className="nav-item">
                                <a  onClick={() => {
                    
                    setPage('bracket');
                  }} className="nav-link" id="bracket-tab" data-toggle="tab" href="#bracket" role="tab" aria-controls="bracket" aria-selected="false">bracket</a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => {
                    
                    setPage('matches');
                  }} className="nav-link" id="matches-tab" data-toggle="tab" href="#matches" role="tab" aria-controls="matches" aria-selected="false">matches</a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => {
                    
                    setPage('participants');
                  }} className="nav-link" id="participants-tab" data-toggle="tab" href="#participants" role="tab" aria-controls="participants" aria-selected="false">participants</a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => {
                    
                    setPage('prizes');
                  }} className="nav-link" id="prizes-tab" data-toggle="tab" href="#prizes" role="tab" aria-controls="prizes" aria-selected="false">prizes</a>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </section>

  <section id="tournaments-content">
  {((page === 'start') || (page === 'overview')) && (
       <TournamentOverview tournament={tournament} />
      )}
  {page === 'bracket' && (
      <TournamentBracket/>
      )}
       {page === 'matches' && (
       <TournamentMatches/>
      )}
   {page === 'participants' && (
      <TournamentParticipants tournament ={tournament} />
      )}
   {page === 'prizes' && (
         <TournamentPrizes/>
      )}


 

  </section>
  </div> )}
  </div>
  );
};

export default TournamentPage;
