
import axios from "axios";
import { Report } from "notiflix";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { logout } from "../../api/apiCalls";
import { myContext } from "../../AuthContext/context";
import FullLoad from "../subComp/FullLoad";

import "./ProfileSettings.css"

import LinkedAccSettings from "./subComp/LinkedAccSettings";

// import PaymentSettings from "./subComp/PaymentSettings";
// import PrivacySettings from "./subComp/PrivacySettings";

const ProfileSettings = () => { 
    const user =  useContext(myContext);
    const [UserSettings,SetUserSettings] = useState({});
    const [loading,SetLoading]=useState(true);
    const get_User_Settings = async () => {
      
        const get_user_settings_api = "https://aqueous-falls-70675.herokuapp.com/api/v1/actions/user/settings/SettingsUser";
        axios.get(get_user_settings_api,{ withCredentials: true }).then((response) => {
        if ((response.data.status === 200) ) {
          SetUserSettings(response.data.result);
          SetLoading(false);
        }
      })
      .catch (error => {
        if (error.response.status === 401 && error.response.statusText==="Unauthorized") {
          
          Report.warning(
            'Session Not Valid',
            "Please Login Again",
            'Okay',
            );
            setTimeout(() => {
              logout();
            }, 2000)
           
        }
      
      }) 
      
    };
  useEffect (()=>{
    get_User_Settings()
    
  }, []);

  return (
    <div>
    
    {loading ? (<FullLoad />) :(
    <div className="container" style={{marginTop:"100px"}}>
      <div className="row gutters-sm">
        <div className="col-md-4 d-none d-md-block">
          <div className="card">
            <div className="card-body ">
              <nav className="nav flex-column nav-pills nav-gap-y-1">
              <Link to="/settings/Info" className="nav-item nav-link has-icon nav-link-faded "> 
               
               <i className="far fa-user mr-2 fa-lg" ></i>Profile Information
               </Link>
               <Link to="/settings/linkedAccount" className="nav-item nav-link has-icon nav-link-faded active"> 
               <i className="fas fa-link mr-2 fa-lg"></i>Linked Accounts
               </Link>
           
                {/* <a href="#notification" data-toggle="tab" className="nav-item nav-link has-icon nav-link-faded">
                <i className="fas fa-user-cog mr-2 fa-lg"></i>Privacy Settings
                </a>
                <a href="#billing" data-toggle="tab" className="nav-item nav-link has-icon nav-link-faded">
                <i className="fas fa-credit-card mr-2 fa-lg"></i>Payout Methode
                </a> */}
              </nav>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="card">
            <div className="card-header border-bottom mb-3 d-flex d-md-none">
              <ul className="nav nav-tabs card-header-tabs nav-gap-x-1" role="tablist">
                <li className="nav-item">
                  <a href="#profile" data-toggle="tab" className="nav-link has-icon active"><i className="far fa-user mr-2"></i></a>
                </li>
                <li className="nav-item">
                  <a href="#account" data-toggle="tab" className="nav-link has-icon"><i className="fas fa-link mr-2"></i></a>
                </li>
               
                <li className="nav-item">
                  <a href="#notification" data-toggle="tab" className="nav-link has-icon"><i className="fas fa-user-cog mr-2"></i></a>
                </li>
                <li className="nav-item">
                  <a href="#billing" data-toggle="tab" className="nav-link has-icon"> <i className="fas fa-credit-card mr-2"></i></a>
                </li>
              </ul>
            </div>
            <div className="card-body tab-content">
            
               
             {/* <InfoSettings user={user} settings={UserSettings}/> */}
             <LinkedAccSettings  user={user} settings={UserSettings} />
              
              {/* <PrivacySettings />
            <PaymentSettings /> */}
            
            </div>
          </div>
        </div>
      </div>
      </div> )} 
    </div>
  );
};

export default ProfileSettings;
