
import './App.css';
import './style.css';
import Home from './comp/Home/home';
import { myContext } from "./AuthContext/context";
import { useContext } from 'react';
import {
 
  Routes,
  Route
} from "react-router-dom";
import SuccessLogin from "./comp/subComp/suceessLogin";
import ProfileSettings from "./comp/ProfileSettings/ProfileSettings";
import ProfileSettingsLink from "./comp/ProfileSettings/ProfileSettingsLink";
import TournamentPage from "./comp/TournamentPage/TournamentPage";
import DiscordLinked from './comp/subComp/DiscordLinked';
const App = () => {
  const user = useContext(myContext);
  
  return (
    <div className="App">
      
      
   <Routes >
     
       <Route  path="/tournament/:name/:id" element={<TournamentPage user={user} />} />
       { user &&  <Route  path="/successLinked" element={<DiscordLinked/>} /> }
      <Route  path="/login/success" element={<SuccessLogin />} />
      { user && <Route  path="/settings" element={<ProfileSettings tab="Info"/>} /> }
      { user && <Route  path="/settings/Info" element={<ProfileSettings tab="Info"/>} /> }
     { user && <Route  path="/settings/linkedAccount" element={<ProfileSettingsLink tab="linkedAccount"/>} /> }
     <Route exact path="/" element={<Home />} />  
    
      </Routes>
 
    </div>
  );
}

export default App;
