


const Loading =() =>{

    return(
        <div id="circle2">
        <div className="loader">
          <div className="loader">
              <div className="loader">
                 <div className="loader">
                     
                 </div>
              </div>
          </div>
        </div>
      </div> 
        );
};

export default Loading;