
import React, { useEffect } from "react";

const DiscordLinked =() =>{
    useEffect(() => {
        setTimeout(() => {
          window.close();
        }, 1000);
      }, []);
    return(
        <div class="success_container">
     <div class="ic_schauhan">
        <i class="fa fa-check" aria-hidden="true"></i>
     </div>
     <p color="white">Discord Linked</p>
     <div class="line"></div>
   </div>

    );
};

export default DiscordLinked;