


const Prize =(props) =>{
   const link="/assets/images/result-img-"+ props.n +".png";

    return(
        <div className="result-single">
        <img src={link} alt="images"/>
       <div className="text-area d-flex justify-content-between align-items-center">
           <span>{props.n} place</span>
           <h4>{props.p} DT</h4>
       </div>
   </div>        );
};

export default Prize;