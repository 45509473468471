import React from 'react'
import { LinkDiscord } from '../../../api/apiCalls';


function LinkDiscordDiv(props) {
    const discord = props.settings.discord;
  
    let DiscordUsername = "Not Linked";
    let DiscordImage= "https://logodownload.org/wp-content/uploads/2017/11/discord-logo-7-1.png";
    if (discord.islinked) {
        DiscordUsername = discord.dc_username;
         DiscordImage= discord.dc_pic;
       
    }
    return (
        <ul className="list-group list-group-sm">
        <li className="list-group-item has-icon  ">
          <div className="row d-flex d-flex align-items-center justify-content-center">
            <div className="col-lg-2 col-4 align-items-center">
              <img
                src={DiscordImage}
                style={{
                    maxHeight: "75px",
                    maxWidth: "75px",
                    borderRadius: "50%",
                  }}
              />
            </div>
            <div className="col-lg-10 col-8 align-items-left">
              <div>
                <h6 className="mb-0">{DiscordUsername}</h6>
                <small className="text-muted">
                  Discord need to be linked to join tournaments
                </small>
              </div>
              {!discord.islinked ? (
                   <button
                   className="btn  btn-sm ml-auto"
                   style={{ backgroundColor: "#5865F2", color: "white" }}
                   type="button" onClick={LinkDiscord}
                 >
                   <i className="fab fa-discord"></i> Link Discord
                 </button>
              ):(
                <button
                className="btn btn-success btn-sm ml-auto" disabled
                style={{  color: "white" }}
                type="button"
              >
                <i className="fas fa-check"></i> linked
              </button>
              ) }
             
            </div>
          </div>
        </li>
      </ul>
    )
}

export default LinkDiscordDiv
