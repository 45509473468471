import Prize from "./prize";
import SideParticipant from "./side_participant";



const TournamentOverview = (props) => {
  const tournament = props.tournament;
  const prize_list=tournament.prize_list;
  const participants= tournament.participant_user;
  console.log(tournament);

  return (
 
  
<div className="tab-content" id="myTabContent">
    <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
        <div className="container pb-120">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <h4 className="head-area">Format</h4>
                    <div className="row wrapper">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="single-area">
                                <img src="/assets/images/format-icon-1.png" alt="image" />
                                <h6>Game</h6>
                                <p className="text-sm">{tournament.game_name}</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="single-area">
                                <img src="/assets/images/format-icon-2.png" alt="image" />
                                <h6>Check-in period</h6>
                                <p className="text-sm">{tournament.check_in}</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="single-area">
                                <img src="/assets/images/format-icon-3.png" alt="image" />
                                <h6>Team Size</h6>
                                <p className="text-sm">{tournament.team_size}</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="single-area">
                                <img src="/assets/images/format-icon-4.png" alt="image" />
                                <h6>Entry Fee</h6>
                                <p className="text-sm">{tournament.fees}</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="single-area">
                                <img src="/assets/images/format-icon-5.png" alt="image" />
                                <h6>Prize Pool</h6>
                                <p className="text-sm">{tournament.prize}</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="single-area">
                                <img src="/assets/images/format-icon-6.png" alt="image" />
                                <h6>Tournament Format</h6>
                                <p className="text-sm">{tournament.format}</p>
                            </div>
                        </div>
                    </div>
                    <div className="info-area">
                        <h4>Information</h4>
                        <div className="single-info">
                            <h5>Format</h5>
                            <ul>
                                <li>Map pool: Selected Aim Maps</li>
                                <li>All kind of cheating will result in a permanent suspension from Begam.</li>
                                <li>Playing with a cheater will result in a 3 month suspension from Begam.</li>
                                <li>Toxic behaviour will cause warnings and in severe cases both disqualifications and
                                    suspensions.</li>
                            </ul>
                        </div>
                        <div className="single-info">
                            <h5>Prize Claim</h5>
                            <p>Prize claims must be completed within 24 hours of the end of the tournament
                                otherwise risk penalty of the prize. Claims can take up to 72 hours to be processed.</p>
                        </div>
                        <div className="single-info">
                            <h5>Rules</h5>
                            <ul>
                                <li>Please be respectful to your host and other participants. If any malicious behavior
                                    is reported, you will be removed from the tournament.</li>
                                <li>Please be on time for your registration and for the actual tournament. You
                                    (and your team) will be disqualified on no-show.</li>
                                <li>You and all of your teammates must be registered to qualify for the event.</li>
                                <li>You can play in this tournament only if your registered and in-game names match,
                                    otherwise you will be disqualified.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="magnific-area">
                        <img src="/assets/images/magnific-bg.png" alt="image" />
                        <a href="../watch-2.html?v=MJ0zpsWQ_XM" className="mfp-iframe popupvideo">
                            <img src="/assets/images/play-icon-2.png" alt="image" />
                        </a>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="sidebar">
                        <div className="single-side">
                            <h5>Participants</h5>
                            <div className="participants">
                                <ul>
                                    <li><span>Registered</span><span>{tournament.enrolled}</span></li>
                                    <li><span>Waiting Queue</span><span>{tournament.waiting_queue}</span></li>
                                    <li><span>Available slots</span><span>{tournament.slots_left}</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="single-side">
                            <div className="part-people d-flex align-items-center justify-content-between">
                                <ul className="d-flex">
                                
                                {participants.slice(0, 9).map((user_info) => (
                           
                           <SideParticipant key={participants.indexOf(user_info)+Math.random().toString(36).substr(2, 9)}  user_info={user_info}/>

))}
 <li><a href="#" className="right-area">{participants.length}+</a></li>
 

                                </ul>
                                
                            </div>
                        </div>
                        <div className="single-side">
                            <div className="head-area d-flex justify-content-between align-items-center">
                                <h5>Results</h5>
                                <a href="#" className="viewall text-sm">View all</a>
                            </div>
                            {prize_list.map((prize) => (
                           
                                    <Prize key={prize_list.indexOf(prize)+Math.random().toString(36).substr(2, 9)} n={prize_list.indexOf(prize)+1} p={prize}/>
     
   ))}
                            
                    </div>
                        
                        <div className="single-side">
                            <h5>Game</h5>
                            <div className="game-area d-flex align-items-center">
                                <img src={tournament.game_pic} alt="images"/>
                                <div className="right-side">
                                   
                                   
                                </div>
                            </div>
                        </div>
                        <div className="single-side">
                            <h5>Admins (3)</h5>
                            <div className="admins-area">
                                <div className="admins-single">
                                    <div className="left-area d-flex align-items-center">
                                        <img src="/assets/images/admin-img-1.png" alt="images"/>
                                        <div className="right-side">
                                            <h6>Davis Rosser</h6>
                                            <p className="text-sm">OLT Support</p>
                                        </div>
                                    </div>
                                    <div className="right-area">
                                        <div className="nice-select"><span className="current single-item share">
                                                <span className="dot"></span>
                                                <span className="dot"></span>
                                                <span className="dot"></span>
                                            </span>
                                            <ul className="list">
                                                <li><a href="#"><i className="fab fa-facebook-f"></i>Share</a></li>
                                                <li><a href="profile.html"><i className="fas fa-share-alt"></i>View Profile</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="admins-single">
                                    <div className="left-area d-flex align-items-center">
                                        <img src="/assets/images/admin-img-1.png" alt="images"/>
                                        <div className="right-side">
                                            <h6>Davis Rosser</h6>
                                            <p className="text-sm">OLT Support</p>
                                        </div>
                                    </div>
                                    <div className="right-area">
                                        <div className="nice-select"><span className="current single-item share">
                                                <span className="dot"></span>
                                                <span className="dot"></span>
                                                <span className="dot"></span>
                                            </span>
                                            <ul className="list">
                                                <li><a href="#"><i className="fab fa-facebook-f"></i>Share</a></li>
                                                <li><a href="profile.html"><i className="fas fa-share-alt"></i>View Profile</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="admins-single">
                                    <div className="left-area d-flex align-items-center">
                                        <img src="/assets/images/admin-img-1.png" alt="images"/>
                                        <div className="right-side">
                                            <h6>Davis Rosser</h6>
                                            <p className="text-sm">OLT Support</p>
                                        </div>
                                    </div>
                                    <div className="right-area">
                                        <div className="nice-select"><span className="current single-item share">
                                                <span className="dot"></span>
                                                <span className="dot"></span>
                                                <span className="dot"></span>
                                            </span>
                                            <ul className="list">
                                                <li><a href="#"><i className="fab fa-facebook-f"></i>Share</a></li>
                                                <li><a href="profile.html"><i className="fas fa-share-alt"></i>View Profile</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  );
};

export default TournamentOverview;


