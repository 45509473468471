import axios from "axios";
import { Report } from "notiflix";
import { useState } from "react";
import { logout } from "../../../api/apiCalls";

const SingleLinkedGame = (props) => {
  let defaultVal ="";
  function check_linked(gameID) {
    if (gameID.game_id === game.id ) {
      
      defaultVal = gameID.linked.game_tag;
     
    }
  }
const [TagStyle, setTagStyle] = useState({});

const [Tag, setTag] = useState(defaultVal);

const game = props.game ;
const user_games= props.user_games;




 user_games.map((gameID) => (
  check_linked(gameID)
 ))


const link="../assets/images/games/links/";

  const [isLoading, setLoading] = useState(false);
  function saveTag() {
    setLoading(true);
    if (!Tag) {
      Report.warning(
        "Invalid Username",
        "You need to fill Tag Correctly",
        "Okay"
      );
      setLoading(false);
    }else{
  const UpdateUserName = async () => {
    const NewTag =Tag.trim();
     const user_update_api =
       "https://aqueous-falls-70675.herokuapp.com/api/v1/actions/user/settings/SaveGameTag";
     axios
       .post(
         user_update_api,
         { game_id: game.id ,game_tag: NewTag},
         { withCredentials: true }
      
       )
       .then((response) => {
         if (response.data.status === 200) {
           Report.success("Saved", response.data.msg, "Okay",()=>{window.location.href = "/settings/linkedAccount";});
           setTimeout(() => {
             window.location.href = "/settings/linkedAccount";
           }, 2000);
           
         } 
       })
       .catch((error) => {
         if (error.response.status === 401 && error.response.statusText==="Unauthorized") {
             Report.warning(
               'Session Not Valid',
               "Please Login Again",
               'Okay',
               );
               setTimeout(() => {
                 logout();
               }, 2000)
            
           }else{
             
             Report.warning(
                 'Joining Error',
                 error.response.data.errors.msg,
                 'Okay',
                 );
                 setLoading(false);
           }
         
       });
   };
   UpdateUserName();
  }
   
 }

  function HandleGameTag(e) {
    
    if (
      e.target.value.length < 1 ||
      e.target.value.length > 50 
    ) {
      setTagStyle({ border: "3px solid red " });
      setTag(null);
    } else {
      setTagStyle({ border: "3px solid green" });
      setTag(e.target.value); 
    }
  }
  return (
    <div>
      <div
        className="card"
        style={{
          backgroundColor: "black",
          borderRadius: "10px",
          padding: "5px",
          width: " 13rem",
          margin: "10px",
          color:"white",
        }}
      >
        <center><small>{game.name}</small></center>
        <img
          max-height="300px"
          src={link+game.link_image}
          className="card-img-top"
          alt="..."
        />
        <div className="card-body d-flex flex-column">
          <div className="mt-auto ">
            <input
              type="text"
              className="form-control"
              id={game.name+"Input"}
              aria-describedby={game.name+"Input "}
              style={TagStyle}
              placeholder={game.name+" tag"}
              defaultValue={defaultVal}
              onChange={
                
                HandleGameTag
              }
            />
          {isLoading ? (<button
              className="btn btn-success mt-1 btn-sm w-100 align-self-end "
              type="button"
            >
             <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>):(<button
              className="btn btn-success mt-1 btn-sm w-100 align-self-end "
              type="button" onClick={ saveTag }
            >
              Save
            </button>)}  
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleLinkedGame;
