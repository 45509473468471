import axios from "axios";
import React, { useEffect } from "react";

const fetchuser = async () => {
  const response = await axios
    .get("https://aqueous-falls-70675.herokuapp.com/getuser", {
      withCredentials: true,
    })
    .catch((err) => {
      console.log(err);
      window.location("/");
      window.location.href("/");
    });
  if (response && response.data) {
    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify(response.data));
  }
};

const SuccessLogin =() =>{
    useEffect(() => {
        fetchuser();
        setTimeout(() => {
          window.close();
        }, 1000);
      }, []);
    return(
        <div className="success_container">
     <div className="ic_schauhan">
        <i className="fa fa-check" aria-hidden="true"></i>
     </div>
     <p color="white">LOGIN SUCCESS</p>
     <div className="line"></div>
   </div>

    );
};

export default SuccessLogin;