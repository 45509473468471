


const SideParticipant =(props) =>{
    const user_info = props.user_info;
    const prof_link= "/u/"+user_info.username;
 
     return(
        <li><a href={prof_link}><img style={{maxWidth:'50px',maxHeigth:'50px',borderRadius:'50%',border:'3px #27263E solid'}} src={user_info.userpic} alt="images"/></a></li>
         );
 };
 
 export default SideParticipant;