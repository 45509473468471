import axios from "axios";
import { Report } from "notiflix";
import { useState } from "react";
import { logout } from "../../../api/apiCalls";
const UsernameDiv = (props) => {
  const user = props.user;
  const settings = props.settings;
 
  
  const [isLoading, setLoading] = useState(false);
  const [StartUserName, setStartUserName] = useState(false);
  const [UserNameStyle, setUserNameStyle] = useState({});
  const [NameUsername, setNameUsername] = useState(null);
  function handleusername() {
    if (!NameUsername) {
      Report.warning(
        "Invalid Username",
        "You need to fill username Correctly",
        "Okay"
      );
    } else {
        setLoading(true);
        const fetchuser = async () => {
          const response = await axios
            .get("https://aqueous-falls-70675.herokuapp.com/getuser", {
              withCredentials: true,
            })
            .catch((err) => {
              console.log(err);
              window.location("/");
              window.location.href("/");
            });
          if (response && response.data) {
            localStorage.removeItem("user");
            localStorage.setItem("user", JSON.stringify(response.data));
          }
        };
      const UpdateUserName = async () => {
       const NewNameUsername =NameUsername.trim().replaceAll("\\s+","") ;
      
        const user_update_api =
          "https://aqueous-falls-70675.herokuapp.com/api/v1/actions/user/settings/ChangeUserName";
        axios
          .post(
            user_update_api,
            { new_user_name: NewNameUsername },
            { withCredentials: true }
          )
          .then((response) => {
            if (response.data.status === 200) {
              fetchuser();
              Report.success("Saved", response.data.msg, "Okay",()=>{window.location.href = "/settings";});
           
              setTimeout(() => {
                window.location.href = "/settings";
              }, 2000);
              
            }
          })
          .catch((error) => {
            if (error.response.status === 401 && error.response.statusText==="Unauthorized") {
                Report.warning(
                  'Session Not Valid',
                  "Please Login Again",
                  'Okay',
                  );
                  setTimeout(() => {
                    logout();
                  }, 2000)
               
              }else{
                 
                Report.warning(
                    'Joining Error',
                    error.response.data.errors.msg,
                    'Okay',
                    );
                    setLoading(false);
              }
            
          });
      };
      UpdateUserName();
    }
  }
  function ChangeUserInput(e) {
    
    if (
      e.target.value.length < 4 ||
      e.target.value.length > 10 ||
      !e.target.value.charAt(0).match(/^[A-Za-z]+$/) 
    || !(e.target.value.slice(1,e.target.value.length).match(/^[A-Za-z0-9_]+$/))
    ) {
      setUserNameStyle({ border: "3px solid red " });
      setNameUsername(null);
    } else {
      setUserNameStyle({ border: "3px solid green" });
      setNameUsername(e.target.value); 
    }
  }
  return (
       
    <ul className="list-group list-group-sm">
        
      <li className="list-group-item has-icon  ">
        <div className="row d-flex d-flex align-items-center justify-content-center">
            
          <div className="col-lg-2 col-4 align-items-center">
            {user && ( 
              <img
                src={user.picture}
                style={{
                  maxHeight: "75px",
                  maxWidth: "75px",
                  borderRadius: "50%",
                  
                }}
                alt="user_pic"
              />
            )}
          </div>
          <div className="col-lg-10 col-8 align-items-left">
            <div>
              {!StartUserName ? (
                user && (
                  <div className="d-flex">
                    <h6 className="mb-1 mr-1">{settings.user.username}</h6>
                    <small>
                      <kbd>#{settings.user.tag}</kbd>
                    </small>
                  </div>
                )
              ) : (
                <input
                  type="text"
                  className="form-control"
                  id="Usernamechange"
                  style={UserNameStyle}
                  aria-describedby="Usernamechange"
                  placeholder={settings.user.username}
                  onChange={ChangeUserInput}
                  onFocus={ChangeUserInput}
                />
              )}
              {!StartUserName ? (
                <small className="text-muted">
                  You can change your username only once
                </small>
              ) : (
                <small className="text-success">
                  must start with a letter , between 4 to 10 (letterrs / numbers / _ ){" "}
                </small>
              )}
            </div>
            {!settings.user.changed ? (
              !StartUserName ? (
                <button
                  onClick={(e) => {
                    setStartUserName(true);
                  }}
                  className="btn btn-light btn-sm ml-auto"
                  type="button"
                >
                  Change Username
                </button>
              ) : (  isLoading ? ( <button
                onClick={(e)=> e.preventDefault}
                className="btn btn-success btn-md ml-auto"
                type="button"
              >
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </button>):( 
                <div>
                  {" "}
                  <button
                    onClick={handleusername}
                    className="btn btn-success btn-md ml-auto"
                    type="button"
                  >
                    Save
                  </button>
                  <button
                    onClick={(e) => {
                      setStartUserName(false);
                      setUserNameStyle({});
                    }}
                    className="btn btn-light btn-sm ml-2"
                    type="button"
                  >
                    cancel
                  </button>
                </div>)
              )
            ) : (
              <button
                onClick={(e) => {
                  Report.warning(
                    "Error",
                    "You Already Changed Your Username Before",
                    "Okay"
                  );
                }}
                className="btn btn-light btn-sm ml-auto disabled"
                type="button"
              >
                 <i className="fas fa-lock"></i> Already Changed
              </button>
            )}
          </div>
        </div>
      </li>
      
    </ul>
  );
};

export default UsernameDiv;
