


const FullLoad =() =>{

    return(
        <div id="circle">
        <div className="loader">
          <div className="loader">
              <div className="loader">
                 <div className="loader">
                 </div>
              </div>
          </div>
        </div>
      </div> 
        );
};

export default FullLoad;