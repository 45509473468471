
const TournamentBracket = (props) => {
   


    return (
      <div>
     <div className="tab-pane fade pb-120" id="bracket" role="tabpanel" aria-labelledby="bracket-tab">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3">
                            <div className="bracket-menu">
                                <div className="single-item text-center">
                                    <h5>Round 1</h5>
                                    <p>8 matches</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3">
                            <div className="bracket-menu">
                                <div className="single-item text-center">
                                    <h5>Quarter Finals</h5>
                                    <p>4 matches</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3">
                            <div className="bracket-menu">
                                <div className="single-item text-center">
                                    <h5>Semi Finals</h5>
                                    <p>2 matches</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3">
                            <div className="bracket-menu">
                                <div className="single-item text-center">
                                    <h5>Finals</h5>
                                    <p>1 matches</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bracket-area">
                        <div className="row w-cus">
                            <div className="col-lg-4 col-4 col">
                                <div className="group-bracket mb-30">
                                    <div className="bracket-single">
                                        <div className="single-bracket">
                                            <ul>
                                                <li>
                                                    <div className="left d-flex align-items-center">
                                                        <img src="/assets/images/team-img-1.png" alt="image"/>
                                                        <p>Abdul Dowdy</p>
                                                    </div>
                                                    <div className="right">
                                                        <span>01</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="left d-flex align-items-center">
                                                        <img src="/assets/images/team-img-2.png" alt="image"/>
                                                        <p>Alva Allred</p>
                                                    </div>
                                                    <div className="right green">
                                                        <span>03</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="single-bracket">
                                            <ul className="two">
                                                <li>
                                                    <div className="left d-flex align-items-center">
                                                        <img src="/assets/images/team-img-3.png" alt="image"/>
                                                        <p>Bill Lackey</p>
                                                    </div>
                                                    <div className="right">
                                                        <span>01</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="left d-flex align-items-center">
                                                        <img src="/assets/images/team-img-4.png" alt="image"/>
                                                        <p>Sue Plante</p>
                                                    </div>
                                                    <div className="right green">
                                                        <span>03</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="group-bracket">
                                    <div className="bracket-single">
                                        <div className="single-bracket">
                                            <ul className="three">
                                                <li>
                                                    <div className="left d-flex align-items-center">
                                                        <img src="/assets/images/team-img-5.png" alt="image"/>
                                                        <p>Abdul Dowdy</p>
                                                    </div>
                                                    <div className="right">
                                                        <span>01</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="left d-flex align-items-center">
                                                        <img src="/assets/images/team-img-6.png" alt="image"/>
                                                        <p>Mittie Abrams</p>
                                                    </div>
                                                    <div className="right green">
                                                        <span>03</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="single-bracket">
                                            <ul className="four">
                                                <li>
                                                    <div className="left d-flex align-items-center">
                                                        <img src="/assets/images/team-img-7.png" alt="image"/>
                                                        <p>Adela Peters</p>
                                                    </div>
                                                    <div className="right">
                                                        <span>01</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="left d-flex align-items-center">
                                                        <img src="/assets/images/team-img-1.png" alt="image"/>
                                                        <p>Owen Boone</p>
                                                    </div>
                                                    <div className="right green">
                                                        <span>03</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-4 col d-grid align-items-center">
                                <div className="group-bracket">
                                    <div className="bracket-single second">
                                        <div className="single-bracket mb-155">
                                            <ul className="five">
                                                <li>
                                                    <div className="left d-flex align-items-center">
                                                        <img src="/assets/images/team-img-1.png" alt="image"/>
                                                        <p>Abdul Dowdy</p>
                                                    </div>
                                                    <div className="right">
                                                        <span>01</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="left d-flex align-items-center">
                                                        <img src="/assets/images/team-img-2.png" alt="image"/>
                                                        <p>Alva Allred</p>
                                                    </div>
                                                    <div className="right green">
                                                        <span>03</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="single-bracket">
                                            <ul className="six">
                                                <li>
                                                    <div className="left d-flex align-items-center">
                                                        <img src="/assets/images/team-img-1.png" alt="image"/>
                                                        <p>Abdul Dowdy</p>
                                                    </div>
                                                    <div className="right">
                                                        <span>01</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="left d-flex align-items-center">
                                                        <img src="/assets/images/team-img-2.png" alt="image"/>
                                                        <p>Alva Allred</p>
                                                    </div>
                                                    <div className="right green">
                                                        <span>03</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-4 col d-grid align-items-center">
                                <div className="group-bracket before-none">
                                    <div className="bracket-single">
                                        <div className="single-bracket">
                                            <ul className="seven">
                                                <li>
                                                    <div className="left d-flex align-items-center">
                                                        <img src="/assets/images/team-img-1.png" alt="image"/>
                                                        <p>Abdul Dowdy</p>
                                                    </div>
                                                    <div className="right">
                                                        <span>01</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="left d-flex align-items-center">
                                                        <img src="/assets/images/team-img-2.png" alt="image"/>
                                                        <p>Alva Allred</p>
                                                    </div>
                                                    <div className="right green">
                                                        <span>03</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    );
  };
  
  export default TournamentBracket;
  