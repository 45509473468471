import Footer from "../Footer/Footer";
import AvailableGames from "./subComp/Available_Games";
import AvailableTournaments from "./subComp/Available_Tournaments";

import HomeBanner from "./subComp/home_banner";
import Tournament4All from "./subComp/Tournament4All";
import { useEffect, useState } from "react";
import axios from "axios";

import Loading from "../subComp/loading"
const Home = (props) => {
    
    const [game_list,SetGame_list]=useState([]);
  const [loading,SetLoading]=useState(false);
  const get_game_list = async () => {
    try {
      const games_api = "https://aqueous-falls-70675.herokuapp.com/api/v1/collect/games/GamesAvailable";
    axios.get(games_api).then((response) => {
      if ((response.data.status === 201) || (response.data.status === 201)) {
       
        SetGame_list(response.data.results);
       var games = {
         games:response.data.results
       };
        localStorage.setItem("games", JSON.stringify(games));
       
        SetLoading(true);
      }else{
        console.log(response.data.errors);
      }
    })
    } catch (error) {
      console.log(error);
    }
    
  };
useEffect (()=>{
  get_game_list()
}, []);
 
 
  return (
    <div>
    
    <HomeBanner />
   {loading ? (<AvailableGames games={game_list}/>):(<Loading/>)} 
    <Tournament4All />
    <AvailableTournaments />
    <Footer/>
    </div>
  );
};

export default Home;
