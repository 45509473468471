import axios from "axios";
const fetchuser = async () => {
  const response = await axios
    .get("https://aqueous-falls-70675.herokuapp.com/getuser", { withCredentials: true })
    .catch((err) => {
      console.log(err);
      window.location("/");
      window.location.href("/");
    });
  if (response && response.data) {
    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify(response.data));
    window.location.reload();
  }
};
const get_game_list = async () => {
  const games_api = "https://aqueous-falls-70675.herokuapp.com/api/v1/collect/games/GamesAvailable";
  axios.get(games_api).then((response) => {
    if ((response.status === 201) || (response.status === 201)) {
     var games = { 
       games:response.data.results
     };
      localStorage.setItem("games", JSON.stringify(games));
    }
  })
};

const RedirectToGoogle = async () => {
  const googleLoginUrl = "https://aqueous-falls-70675.herokuapp.com/login/google";
  const newWindow = window.open(
    googleLoginUrl,
    "_blank",
    "width=500,height=600"
  );
  if (newWindow) {
    const timer = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(timer);
       fetchuser();
        
      }
    }, 500);
  }
};

const LinkDiscord = async () => {
  const discordLoginUrl = "https://aqueous-falls-70675.herokuapp.com/api/v1/actions/user/Link/Discord";
  const newWindow = window.open(
    discordLoginUrl,
    "_blank",
    "width=500,height=600"
  );
  if (newWindow) {
    const timer = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(timer);
        window.location.href = "/settings";
        
      }
    }, 500);
  }
};
const RedirectToDiscord = async () => {
  const discordLoginUrl = "https://aqueous-falls-70675.herokuapp.com/login/discord";
  const newWindow = window.open(
    discordLoginUrl,
    "_blank",
    "width=500,height=600"
  );
  if (newWindow) {
    const timer = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(timer);
        fetchuser();
        
      }
    }, 500);
  }
};
const logout = async () => {
  localStorage.removeItem("user");
  axios
    .get("https://aqueous-falls-70675.herokuapp.com/logout", { withCredentials: true })
    .then((res) => {
      if (res.data === "loggedout") {
        window.location.href = "/";
      }
    });
};
export { RedirectToGoogle, RedirectToDiscord, logout, get_game_list,LinkDiscord };
