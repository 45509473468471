import axios from "axios";
import { Report } from "notiflix";
import { useState } from "react";
import { logout } from "../../../api/apiCalls";
import Loading from "../../subComp/loading";
import UsernameDiv from "./UsernameDiv";


const InfoSettings = (props) => {
  const user = props.user;
  const UserSettings = props.settings;
 const [InfosLock,SetInfosLock] = useState(UserSettings.user.infos);
 const [isLoading, setLoading] = useState(false);
  const [Name, setName] = useState(UserSettings.fullname);
  const [Phone, setPhone] = useState(UserSettings.phone);
  const [Location, setLocation] = useState(UserSettings.adress);
  const [NameStyle, setNameStyle] = useState({});
  const [PhoneStyle, setPhoneStyle] = useState({});
  const [LocationStyle, setLocationStyle] = useState({});
 
 
  function handleInfos(e) {
    e.preventDefault();
    let infos ={
      Name,
      Phone,
      Location
    }
    if( !(infos.Name && infos.Phone && infos.Location) ) {
      Report.warning(
        'invalid information',
        "You need to fill all informations Correctly",
        'Okay',
        );
     
    }else{
      setLoading(true);
      const UpdateUserInfo = async () => {
          const user_update_api = "https://aqueous-falls-70675.herokuapp.com/api/v1/actions/user/settings/UpdateProfile";
        axios.post(user_update_api,{f_n:infos.Name,p_n:infos.Phone,address:infos.Location},{ withCredentials: true }).then((response) => {
          if ((response.data.status === 200)) {
            Report.success(
              'Saved',
              "Informations Updated successfully",
              'Okay',()=>{window.location.href = "/settings";}
              );
              setTimeout(() => {
                window.location.href = "/settings";
              }, 2000);
          }else{
            console.log(response);
          }
        }).catch (error => {
          if (error.response.status == 401 && error.response.statusText==="Unauthorized") {
            Report.warning(
              'Session Not Valid',
              "Please Login Again",
              'Okay',
              );
              setTimeout(() => {
                logout();
              }, 2000)
           
          }else{
              console.log(error.response.data.errors.msg);
            Report.warning(
                'Joining Error',
                error.response.data.errors.msg,
                'Okay',
                );
                setLoading(false);
          }
          
          }) 
          
        } 
        UpdateUserInfo();

    }
    
  }


    function handlefullname(e) {
     
        if (e.target.value.length <4 || e.target.value.length >30  || !(e.target.value.match(/^[A-Za-z\s]+$/)) ) {
            setNameStyle({border: '3px solid red '});
            setName(null);
          } else {
            setNameStyle({border: '3px solid green'});
            setName(e.target.value);
    }  }
    function handlePhone(e) {
      
        if((e.target.value.charAt(0) != "+") || e.target.value.length <5 || e.target.value.length>20 || !(e.target.value.slice(1,e.target.value.length).match(/^[0-9]+$/)) ){
        setPhoneStyle({border: '3px solid red '});
        setPhone(null);
      } else{
        setPhoneStyle({border: '3px solid green'});
        setPhone(e.target.value);
    }  }
    function handleLocation(e) {
     
        if( e.target.value.length>50 || e.target.value.length <3 ) {
        setLocationStyle({border: '3px solid red '});
        setLocation(null);
        }else {
        setLocationStyle({border: '3px solid green'});
        setLocation(e.target.value);
    }  }

  return (
    <div className="tab-pane active" id="profile">
      <h6>YOUR PROFILE INFORMATION</h6>
      <hr />
   <UsernameDiv user={user} settings={UserSettings}/>
            <hr />
      <form>
        <div className="form-group">
          <label htmlFor="fullName">Full Name</label>
       { !InfosLock ?  ( <input
            type="text"
            className="form-control"
            id="fullName"
            style={NameStyle}
            aria-describedby="fullNameHelp"
            placeholder="Enter your fullname"
            placeholder="Foulen Ben Foulen"
            defaultValue={UserSettings.fullname}
            onChange={
              handlefullname
            }
            onFocus={handlefullname}
          />):(
           <p style={{fontSize:"16px"}}> {UserSettings.fullname} </p>
          )}
          <small id="fullNameHelp" className="form-text text-muted">
            Your name will not appear anywhere , we just need this to confirm
            payout informations ..
          </small>
        </div>

    

        <div className="form-group">
          <label htmlFor="PhoneNumber">Phone Number</label>
          { !InfosLock ?  (       <input
            type="text"
            className="form-control"
            id="PhoneNumber"
            style={PhoneStyle}
            aria-describedby="Phone Number"
            placeholder="+21600000000"
            defaultValue={UserSettings.phone}
            onChange={
              handlePhone
            }
            onFocus={handlePhone}
          />):(
            <p style={{fontSize:"16px"}}> {UserSettings.phone} </p>
          
          )}
          <small id="fullNameHelp" className="form-text text-muted">Your Phone Number is Private As Well</small>
            
        
        </div>

        <div className="form-group">
          
          <label htmlFor="location">Full Address</label>
          
          { !InfosLock ?  (  <input
            type="text"
            className="form-control"
            id="Address"
            style={LocationStyle}
            placeholder="Enter your location"
            placeholder="Rue foulen 10 , 8002 , Nabeul ,Tunisia"
            defaultValue={UserSettings.adress}
            onChange={
              handleLocation
            }
            onFocus={handleLocation}
          />):(
            <p style={{fontSize:"16px"}}> {UserSettings.adress} </p>
            
          )}
          <small id="AddressHelp" className="form-text text-muted"> Your Address will not appear anywhere , we just need this to confirm payout informations ..</small>
        </div>
        
       
        { !InfosLock ?  ( 
        !isLoading ? (<button onClick ={handleInfos} type="button" className="btn btn-success ">
          Update Profile
        </button>):(<button  type="button" className="btn btn-success ">
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>) ):(<button onClick ={(e)=>{
          e.preventDefault()
        }} type="button" disabled className="btn btn-success ">
          <i className="fas fa-lock"></i> Update Profile
        </button>)}
        <br></br>
        { !InfosLock ?  (  <small style={{color:"red"}}> make sure your informations are correct ! you won't be able to change them later</small>):
        (<small style={{color:"red"}}>Please contact support if you want to update your informations</small>)}
      </form>
    </div>
  );
};

export default InfoSettings;
