import { Link } from "react-router-dom";
import { logout } from "../../../../api/apiCalls";
const UserBubble = (props) => {
  const user = props.user;
  const pic = user.picture;
  return (
    <div>
    <li className="nav-item dropdown no-arrow">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id="userDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span className="mr-2 d-none d-lg-inline text-gray-600 small" style={{fontFamily:" 'Manrope', sans-serif"}}>
          {user.username}
        </span>
        <img className="img-profile rounded-circle" src={pic} />
      </a>
      <div
        className="dropdown-menu dropdown-menu-bottom shadow animated--grow-in"
        aria-labelledby="userDropdown"
      >
        <a className="dropdown-item" href="#">
          <i className="fa fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
          Profile
        </a>
        <Link className="dropdown-item" to={{ pathname: "/settings"}}>  
       
          <i className="fa fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
          Settings
       
        </Link>
        <a className="dropdown-item" href="#">
          <i className="fa fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
          Activity Log
        </a>
        <div className="dropdown-divider"></div>
        <a className="dropdown-item" href="#" onClick={logout}>
          <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
          Logout
        </a>
      </div>
    </li>
    </div>
  );
};

export default UserBubble;
