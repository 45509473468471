import Participant from "./participant";

const TournamentParticipants = (props) => {
   const participants = props.tournament.participant_user;


    return (
        <div className="tab-pane fade" id="participants" role="tabpanel" aria-labelledby="participants-tab">
        <div className="participants">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="participants-area pb-120">
                            <h4>Confirmed</h4>
                            {participants.map((user_info) => (
                           
                           <Participant key={participants.indexOf(user_info)+Math.random().toString(36).substr(2, 9)}  user_info={user_info}/>

))}
                           


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  };
  
  export default TournamentParticipants;
  