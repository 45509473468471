import LinkDiscordDiv from "./LinkDiscordDiv";
import SingleLinkedGame from "./SingleLinkedGame";


const LinkedAccSettings = (props) => {
 

  const games =[{
    id:"61be4cc2e0d00782cf55d5d6",
    link_image:"link_apex.jpg",
    name:"Apex Legend"
  },
  {
    id:"61be4dd665d407f3f6c00517",
    link_image:"link_valorant.jpg",
    name:"Valorant"
  },
  {
    id:"61be4ea5ad90d59df62adeb7",
    link_image:"link_lol.jpg",
    name:"League Of Legend"
  },
  {
    id:"61be4fe76ac6786fda3fdb80",
    link_image:"link_freefire.jpg",
    name:"Free Fire"
  },
  {
    id:"61be50a63a82850cd4ef278a",
    link_image:"link_fortnite.jpg",
    name:"Fortnite"
  },
  {
    id:"61be50d994f397a0516b2e1d",
    link_image:"link_rocketleague.jpg",
    name:"Rocket League"
  },
]
  const UserSettings = props.settings;
  return (
    <div className="tab-pane active" id="account">
      <h6>Discord</h6>
      <hr />
     <LinkDiscordDiv settings={UserSettings}/>
      <hr />
      <h6>Gaming Tag</h6>
      <hr />
      <div className="row justify-content-center">
      { games.map((game) => (
         <SingleLinkedGame key={game.id} game={game} user_games={UserSettings.games_accounts}/>
        ))}
      
      </div>
      <hr />
      {/* <h6>Game Tag</h6>
      <hr />
      <div className="row justify-content-center">
        <SingleLinkedGame />
        <SingleLinkedGame />
        <SingleLinkedGame />
      
      </div> */}
    </div>
  );
};

export default LinkedAccSettings;
