import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import  Context  from "./AuthContext/context";

import Header from "./comp/subComp/Header";
import { BrowserRouter } from "react-router-dom";



ReactDOM.render(
  <React.StrictMode>
      <Context>
      
      <BrowserRouter>
      <Header /> 
       <App/>
       </BrowserRouter>
      </Context>
  </React.StrictMode>,
  document.getElementById("root")
);
