

const Tournament4All =() =>{

    return(
            <div>
        <section id="tournaments-everyone" className="pt-120 pb-120">
        <div className="container">
            <div className="row">
                <div className="col-lg-5 col-md-5 d-flex align-items-center">
                    <div className="section-header">
                        <h2 className="title">Tournaments for everyone</h2>
                        <p>Free tournaments for players of all skill-levels in the best esports games.</p>
                        <a href="registration.html" className="cmn-btn">Join now!</a>
                    </div>
                </div>
                <div className="col-lg-7 col-md-7">
                    <div className="right-area">
                        <img src="/assets/images/tournaments-tree.png" alt="image" />
                    </div>
                </div>
            </div>
        </div>
    </section>
 
    <section id="claim-rank">
        <div className="container">
            <div className="row">
                <div className="col-lg-7 col-md-7 d-flex align-items-center">
                    <div className="right-area">
                        <img src="/assets/images/clime-rank.png" alt="image" />
                    </div>
                </div>
                <div className="col-lg-5 col-md-5 d-flex align-items-center">
                    <div className="section-header">
                        <h2 className="title">Claim your rank</h2>
                        <p>Play matchmaking and tournaments to claim your Tier and earn a place on the leaderboard - the official ranking on Begam.</p>
                        <a href="registration.html" className="cmn-btn">Join now!</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
  
    <section id="esports-matches" className="pt-120 pb-120">
        <div className="container">
            <div className="row">
                <div className="col-md-5 d-flex align-items-center">
                    <div className="section-header">
                        <h2 className="title">Esports matches on demand.</h2>
                        <p>Matchmaking queues open 24/7 for quick and fun games. The best and most active players win prizes every week.</p>
                        <a href="registration.html" className="cmn-btn">Join now!</a>
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="right-area">
                        <img src="/assets/images/esports-matches.png" alt="image" />
                    </div>
                </div>
            </div>
        </div>
    </section>
  
   
    </div>
    );
};

export default Tournament4All;