import Countdown from "react-countdown";
import { Link } from "react-router-dom";
const TournamentPreview = (props) => {
  const tournament = props.tournament;
  var dt = new Date(tournament.date);
 var clean_name = tournament.title.replace(/\s+/g, '');
  var link = "/tournament/" +clean_name+ "/" + tournament.id;
  return (
    <div>
      <div className="single-item">
        <div className="row">
          <div className="col-lg-3 col-md-3 d-flex align-items-center">
            <img className="top-img" src={tournament.image} alt="image" />
          </div>
          <div className="col-lg-6 col-md-9 d-flex align-items-center">
            <div className="mid-area">
              <h4>{tournament.title}</h4>
              <div className="title-bottom d-flex">
                <div className="time-area bg">
                  <img src="/assets/images/waitng-icon.png" alt="image" />
                  <span>
                    Starts in <span></span>
                  </span>
                  <span className="time">
                    {" "}
                    <Countdown date={dt} />{" "}
                  </span>
                </div>
                <div className="date-area bg">
                  <span className="date">{dt.toDateString()}</span>
                </div>
              </div>
              <div className="single-box d-flex">
                <div className="box-item">
                  <span className="head">ENTRY/PLAYER </span>
                  <span className="sub">{tournament.fees} <span></span></span>
                </div>
                <div className="box-item">
                  <span className="head">Team Size </span>
                  <span className="sub">{tournament.type}</span>
                </div>
                <div className="box-item">
                  <span className="head">Max Teams </span>
                  <span className="sub">{tournament.max_teams}</span>
                </div>
                <div className="box-item">
                  <span className="head">Enrolled </span>
                  <span className="sub">{tournament.enrolled}</span>
                </div>
                <div className="box-item">
                  <span className="head">skill Level </span>
                  <span className="sub">{tournament.skill}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 d-flex align-items-center">
            <div className="prize-area text-center">
              <div className="contain-area">
                <span className="prize">
                  <img src="/assets/images/price-coin.png" alt="image" />
                  prize
                </span>
                <h4 className="dollar">{tournament.prize}</h4>
                <Link to={{ pathname: link, tour:props.tournament }}> <p className="cmn-btn">
                  View Tournament
                </p></Link>
                <p>Top 3 Players Win a Cash Prize</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TournamentPreview;
