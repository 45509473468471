import React from "react";

import axios from "axios";

import { Report } from "notiflix/build/notiflix-report-aio";
import { logout } from "../../../api/apiCalls";
import { useParams } from "react-router-dom";
import { useState } from "react/cjs/react.development";
import { Confirm } from "notiflix";

function JoinTournamentButton(props) {
  let user = props.user;
  let tournament = props.tour;
  const params = useParams();
const [isLoading,SetisLoading]=useState(false);
  let UserJoined = false;

  let participants = tournament.participant_user;

  participants.forEach((part_user) => {
    if (part_user.user_id === user.id) {
      console.log(part_user.user_id);
      console.log(user.id);
      UserJoined = true;
      console.log("joined");
    }
  });
  const join_tournament = async () => {
    SetisLoading(true);
    
    const tournament_api =
      "https://aqueous-falls-70675.herokuapp.com/api/v1/actions/tournaments/JoinTournament";
    axios
      .post(tournament_api, { tour_id: params.id }, { withCredentials: true })
      .then((response) => {
        if (response.data.status === 200) {
          Report.success(
            "Success",
            "You successfully joined this tournament",
            "Okay"
          );
          SetisLoading(false);
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        if (
          error.response.status === 401 &&
          error.response.statusText === "Unauthorized"
        ) {
          Report.warning("Session Not Valid", "Please Login Again", "Okay");
          setTimeout(() => {
            logout();
          }, 2000);
        }
        console.log(error.response);

        Report.warning("Joining Error", error.response.data.errors.msg, "Okay");
        SetisLoading(false);
      });
  };

  const LeaveTournamentConfirm = async () => {
    SetisLoading(true);
    Confirm.show( 
      'This Action Is Irreversible !',
      "If you leave you won't be able to join this tournament again !<br/> Are you Sure you want to continue ?",
      "Yes",
      "No",
      () => {
        LeaveTournament()
        },
        () => {
          SetisLoading(false);
        },
        {okButtonBackground:"red",backOverlayColor:"rgba(211, 26, 0,0.5)",titleColor:"red"}
    );
      }
      const LeaveTournament = async () => {
    const tournament_api =
      "https://aqueous-falls-70675.herokuapp.com/api/v1/actions/tournaments/LeaveTournament";
    axios
      .post(tournament_api, { tour_id: params.id }, { withCredentials: true })
      .then((response) => {
        if (response.data.status === 200) {
          Report.success(
            "Success",
            "You Successfully Left This Tournament",
            "Okay"
          );
          SetisLoading(false);
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        if (
          error.response.status === 401 &&
          error.response.statusText === "Unauthorized"
        ) {
          Report.warning("Session Not Valid", "Please Login Again", "Okay");
          setTimeout(() => {
            logout();
          }, 2000);
        }
        console.log(error.response);

        Report.warning("Error", "Our Server is Eating a Cookie , please pardon him :( ", "Okay");
        SetisLoading(false);
      });
  };
  return (
    <div>
      {!UserJoined ? (
        <button
          href="#"
          id="JoinTournamentBtn"
          onClick={(e) => {
            e.preventDefault();
             join_tournament();
          }}
          className="cmn-btn"
        >
          {isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> } Join Now!
        </button>
      ) : (
        <button
          id="LeaveTournamentBtn"
          style={{ backgroundColor: "red" }}
          className="btn btn-danger"
          onClick={(e) => {
            e.preventDefault();
            LeaveTournamentConfirm();
          }}
        >
        {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <i className="fas fa-sign-out-alt fa-md fa-fw mr-2 "></i>} Leave
          Tournament
        </button>
      )}
    </div>
  );
}

export default JoinTournamentButton;
