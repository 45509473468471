
import { useState } from "react";
import { RedirectToDiscord, RedirectToGoogle } from "../../../../api/apiCalls";
import Loading from "../../../subComp/loading"
const Login =() =>{
  const [loading,SetLoading]=useState(false);
    return(
        <div>
    

    <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-md modal-dialog-centered ">
    <div className="modal-content justify-content-center " id="login_modal" >
    
      <div className="modal-body justify-content-center">
     <center> <h3>Login/Register</h3></center>
      {!loading ? (
       <div className="row  align-items-center">
     
      <div className="col-12 justify-content-center " style={{padding :"50px"}} >
       
        <div className="row justify-content-center">
         <button  onClick={() => {
                    RedirectToGoogle();
                    SetLoading(true);
                  }} className="btn  btn-google btn-block text-uppercase text-wrap word-wrap: break-word;" style={{height:"3rem",width:"20rem",color:"white",backgroundColor:"#4285F4",fontSize: "0.80rem"}} type="submit"><div className="row d-flex justify-content-center"><div className="col-2"><i className="fab fa-google mr-2"></i></div><div className="col-10 text-wrap"> Sign in with Google </div></div></button>
         </div>
         <hr className="login_my_4" />
         <div className="row justify-content-center">
         
          <button  onClick={() => {
                    RedirectToGoogle();
                    SetLoading(true);
                  }} className="btn  btn-facebook btn-block text-uppercase text-wrap word-wrap: break-word;"  style={{height:"3rem",width:"20rem",color:"white",backgroundColor:"#3b5998 ",fontSize: "0.80rem"}} type="submit"><div className="row d-flex justify-content-center"><div className="col-2"> <i className="fab fa-facebook-f mr-2"></i> </div><div className="col-10 text-wrap"> Sign in with Facebook </div></div></button>
          </div>
          <hr className=" login_my_4" />
         <div className="row justify-content-center">
         
          <button  onClick={() => {
                    RedirectToDiscord();
                    SetLoading(true);
                  }} className="btn btn-discord btn-block text-uppercase text-wrap word-wrap: break-word;"  style={{height:"3rem",width:"20rem",color:"white",backgroundColor:"#5865F2" ,fontSize: "0.80rem"}} type="submit"><div className="row d-flex justify-content-center"><div className="col-2"> <i className="fab fa-discord"></i></div>  <div className="col-10 text-wrap"> Sign in with Discord</div></div></button>
          </div>
          
          </div>
          
          
       </div>
)
          :( <Loading />   )}
<center><button style={{marginTop:"10px",padding:"10px",borderRadius:"5px"}}type="button" className="btn-danger" data-dismiss="modal">Cancel</button></center>
      </div>
     
    </div>
  </div>
</div>



</div>
    );
};

export default Login;