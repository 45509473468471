import { useContext } from "react";
import { Link } from "react-router-dom";
import { myContext } from "../../AuthContext/context";
import Login from "../Home/subComp/login/login";
import UserBubble from "../Home/subComp/login/user_bubble";

const Header = () => {
  const user = useContext(myContext);
  return (
    <div>
 <nav className="navbar navbar-expand-md navbar-dark  main-nav fixed-top"  style={{ backgroundColor: "rgb(28,0,1)"}}>
    <div className="container">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse w-100" >
            <ul className="nav navbar-nav w-100" style={{}}>
                <li className="nav-item active m-1">
                <Link className="navbar_item_links" to={{ pathname: "/"}}> Home</Link>
               
                </li>
                <li   className="nav-item m-1">
                <a href="#"  className="navbar_item_links" >Games</a>
                
                </li>
                <li href="#"  className="nav-item m-1">
                <a href="#" className="navbar_item_links" >Tournament</a>
                
                </li>
              
            </ul>
        </div>
      <img className="navbar-brand order-first order-md-0 mx-0" style={{maxHeight:"200px",maxWidth:"200px"}} src="/assets/images/logofm.png" />
        <div className="collapse navbar-collapse w-100 justify-content-end">
        {!user ? (
            <ul className="navbar-nav ml-auto align-items-center">
              <div className="right-area header-action d-flex align-items-center">
                <a
                  href="registration.html"
                  className="cmn-btn"
                  data-toggle="modal"
                  data-target=".bd-example-modal-lg"
                >
                  login/register
                </a>
              </div>{" "}
              
            </ul>
          ) : (
            <ul className="nav navbar-nav ml-auto align-items-center">
               
                {user && <UserBubble user={user} />}
                
            </ul> )}
        </div>
    </div>
    
</nav>
 {!user && <Login />}
    </div>
  );
};

export default Header;
