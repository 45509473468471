const Participant =(props) =>{
    const user_info = props.user_info;
    const prof_link= "/u/"+user_info.username;
 
     return(
        <div className="participants-single">
        <div className="left-area d-flex align-items-center">
            <img src={user_info.userpic} alt="images"/>
            <div className="right-side">
                <h6>{user_info.username}</h6>
            </div>
        </div>
        <div className="right-area">
            <div className="nice-select"><span className="current single-item share">
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                </span>
                <ul className="list">
                    <li><a href={prof_link}><i className="fas fa-share-alt"></i>View Profile</a></li>
                </ul>
            </div>
        </div>
    </div>
         );
 };
 
 export default Participant;