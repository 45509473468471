import React, { Component } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export default class SimpleSlider  extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
      };
        const games= this.props.games;
   
    return (
      <div>

<section id="available-game-section">
        <div className="overlay pb-120">
            <div className="container wow fadeInUp">
                <div className="main-container">
                    <div className="row justify-content-between">
                        <div className="col-lg-10">
                            <div className="section-header">
                                <h2 className="title">Available Games</h2>
                                <p>We are constantly adding new games</p>
                            </div>
                        </div>
                    </div>
                    <Slider {...settings} >
        {games.map((game) => (
         
              <div key={game.title} className="single-item slider_item" >
             <a href="#"> <img className="swiper-slide " src={game.picture.cover} alt="" /> </a>
             </div>
          
        ))}
          
        </Slider>

                    
                    <div className="btn-area text-center">
                        <a href="tournaments.html" className="cmn-btn">View All</a>
                    </div>
                </div>
            </div>
        </div>
    </section>


   
      
      </div>
    );
  }
}