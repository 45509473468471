
const TournamentPrizes = (props) => {
   


  return (
    <div className="tab-pane fade" id="prizes" role="tabpanel" aria-labelledby="prizes-tab">
    <div className="prizes pb-120">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Placement</th>
                                    <th scope="col">Current Prize</th>
                                    <th scope="col">Potential Prize</th>
                                    <th scope="col">Team</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className="first" scope="row">1st</th>
                                    <td>$100.00</td>
                                    <td>$100.00</td>
                                    <td><i className="fas fa-users"></i>To be decided</td>
                                </tr>
                                <tr>
                                    <th className="second" scope="row">2nd</th>
                                    <td>$60.00</td>
                                    <td>$60.00</td>
                                    <td><i className="fas fa-users"></i>To be decided</td>
                                </tr>
                                <tr>
                                    <th className="third" scope="row">3rd</th>
                                    <td>$40.00</td>
                                    <td>$40.00</td>
                                    <td><i className="fas fa-users"></i>To be decided</td>
                                </tr>
                                <tr>
                                    <th scope="row">4th</th>
                                    <td>$25.00</td>
                                    <td>$25.00</td>
                                    <td><i className="fas fa-users"></i>To be decided</td>
                                </tr>
                                <tr>
                                    <th scope="row">5th - 6th</th>
                                    <td>$20.00</td>
                                    <td>$20.00</td>
                                    <td><i className="fas fa-users"></i>To be decided</td>
                                </tr>
                                <tr>
                                    <th scope="row">7th - 8th</th>
                                    <td>$15.00</td>
                                    <td>$15.00</td>
                                    <td><i className="fas fa-users"></i>To be decided</td>
                                </tr>
                                <tr>
                                    <th scope="row">9th - 11th</th>
                                    <td>$10.00</td>
                                    <td>$10.00</td>
                                    <td><i className="fas fa-users"></i>To be decided</td>
                                </tr>
                                <tr>
                                    <th scope="row">12th - 15th</th>
                                    <td>$5.00</td>
                                    <td>$5.00</td>
                                    <td><i className="fas fa-users"></i>To be decided</td>
                                </tr>
                                <tr>
                                    <th scope="row">16th - 19th</th>
                                    <td>$4.00</td>
                                    <td>$4.00</td>
                                    <td><i className="fas fa-users"></i>To be decided</td>
                                </tr>
                                <tr>
                                    <th scope="row">20th - 26th</th>
                                    <td>$3.00</td>
                                    <td>$3.00</td>
                                    <td><i className="fas fa-users"></i>To be decided</td>
                                </tr>
                                <tr>
                                    <th scope="row">27th - 34th</th>
                                    <td>$2.00</td>
                                    <td>$2.00</td>
                                    <td><i className="fas fa-users"></i>To be decided</td>
                                </tr>
                                <tr>
                                    <th scope="row">35th - 44th</th>
                                    <td>$1.00</td>
                                    <td>$1.00</td>
                                    <td><i className="fas fa-users"></i>To be decided</td>
                                </tr>
                                <tr>
                                    <th scope="row">45th - 75th</th>
                                    <td>$0.75</td>
                                    <td>$0.75</td>
                                    <td><i className="fas fa-users"></i>To be decided</td>
                                </tr>
                                <tr>
                                    <th scope="row">76th - 115th</th>
                                    <td>$0.50</td>
                                    <td>$0.50</td>
                                    <td><i className="fas fa-users"></i>To be decided</td>
                                </tr>
                                <tr>
                                    <th scope="row">116th - 180th</th>
                                    <td>$0.25</td>
                                    <td>$0.25</td>
                                    <td><i className="fas fa-users"></i>To be decided</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

  );
};

export default TournamentPrizes;
